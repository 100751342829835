<template>
    <div id="resident-order-summary" class="data-list-container">
        <div
            class="back-section"
            @click="onBackBtnClick"
        >
            <vs-button
                radius
                class="back-btn"
                icon="keyboard_arrow_left"
            />
            <div class="back-legend">
                Récapitulatif {{ getMonthLabel(month) }} {{ year }}
            </div>
        </div>

        <div
            class="order"
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
        >
            <div class="order-header">
                <div class="order-vendor">{{ item.sellerName }}</div>
                <div class="order-number">N° {{ item.id }}</div>
                <div class="order-date">{{ getDateLabel(item.date) }}</div>
                <div class="order-status">
                    <img class="status-icon" :src="getOrderStatusIcon(item)" />
                    <div class="status-label">{{ getStatusText(item) }}</div>
                </div>
                <div class="order-subscriptions">
                    <img class="subscriptions-icon" src="" />
                    <div class="subscriptions-label">{{ getPaymentTypeLabel(item.paymentType) }}</div>
                </div>
                <div class="order-price">{{ item.amount }}€</div>
            </div>
            <div class="order-columns">
                <div class="column-title">Désignation du {{ item.type == 'product' ? 'produit' : 'service' }}</div>
                <div class="column-quantity">Quantité</div>
                <div class="column-price">Prix</div>
            </div>
            <div class="order-items">
                <div
                    class="order-item"
                    v-for="(product, productIndex) in item.products"
                    :key="productIndex"
                >
                    <img class="item-photo" :src="product.photo" />
                    <div class="item-informations">
                        <div class="item-title">{{ product.title }}</div>
                        <div class="item-description">{{ product.description }}</div>
                        <div class="item-legend">{{ product.legend }}</div>
                    </div>
                    <div class="item-quantity">{{ product.quantity }}</div>
                    <div class="item-price">{{ product.price }}€</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'

import Routes from '@/constants/routes'
import Formats from '@/constants/formats'

import {
    getMonthLabel,
    getPaymentTypeLabel,
    getOrderStatusLabel
} from '@/modules/labels'
import {
    getPaymentTypeIcon,
    getOrderStatusIcon
} from '@/modules/icons'
import {
    getTimestamp,
    formatLocalDate
} from '@/modules/date'

export default {
    name: 'OrderSummary',
    props: [
        'resident_id',
        'month',
        'year'
    ],
    components: {

    },
    data () {
        return {
            loaded: false,
            resident: {},
            orders: [],
            items: []
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getCurrentResident () {
            return this.getResidentById(this.resident_id)
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getMonthLabel (month) {
            return getMonthLabel(month)
        },
        getPaymentTypeLabel (type) {
            return getPaymentTypeLabel(type)
        },
        getOrderStatusIcon (order) {
            return getOrderStatusIcon(order)
        },
        getStatusText (order) {
            return getOrderStatusLabel(order)
        },
        getDateLabel (date) {
            return formatLocalDate(date, Formats.OrderDate)
        },
        onBackBtnClick (e) {
            this.$router.push({
                name: Routes.ResidentHistory,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        updateItems () {
            this.items = []
            this.orders.forEach((order) => {
                const paymentType = _.get(order, 'metadata.order_type', '')
                _.forEach(order.orderLogisticProducts, (logisticProduct) => {
                    var amount = 0
                    var products = []
                    const orderLines = _.get(logisticProduct, 'orderContent.orderLines', [])
                    orderLines.forEach((orderLine) => {
                        var taxes = orderLine.totalPrice
                        taxes += _.sumBy(orderLine.shippingTaxes, (tax) => {
                            return tax.amount
                        })
                        taxes += _.sumBy(orderLine.taxes, (tax) => {
                            return tax.amount
                        })
                        amount += taxes
                        const id = _.get(orderLine, 'productSku', 0)
                        const photo = _.get(orderLine, 'productMedias[0].mediaUrl', '')
                        const title = _.get(orderLine, 'productTitle', '')
                        const description = _.get(orderLine, 'description', '')
                        const quantity = _.get(orderLine, 'quantity', 0)
                        const price = _.get(orderLine, 'priceUnit', 0.0)
                        amount += (price * quantity)
                        products.push({
                            id,
                            photo,
                            title,
                            description,
                            legend: '',
                            quantity,
                            price
                        })
                    })
                    const sellerName = _.get(logisticProduct, 'orderContent.shopName', '')
                    const status = _.get(logisticProduct, 'orderContent.orderState', '')
                    const date = _.get(logisticProduct, 'orderContent.createdDate', '')
                    this.items.push({
                        id: logisticProduct.id,
                        type: 'product',
                        date,
                        sellerName,
                        orderId: logisticProduct.id,
                        status,
                        paymentType,
                        amount: amount.toFixed(2),
                        products
                    })
                })
                _.forEach(order.orderLogisticServices, (logisticService) => {
                    var products = []
                    const id = _.get(logisticService, 'orderContent.service.code', 0)
                    const photo = _.get(logisticService, 'orderContent.service.medias[0].url', '')
                    const title = _.get(logisticService, 'orderContent.service.title', '')
                    const description = _.get(logisticService, 'orderContent.service.description', '')
                    const price = _.get(logisticService, 'orderContent.totalPriceTTC', 0.0)
                    products.push({
                        id,
                        photo,
                        title,
                        description,
                        legend: '',
                        quantity: 1,
                        price
                    })
                    const sellerName = _.get(logisticService, 'orderContent.shop.name', '')
                    const status = _.get(logisticService, 'orderContent.state', '')
                    const date = _.get(logisticService, 'orderContent.dateCreated', '')
                    this.items.push({
                        id: logisticService.id,
                        type: 'service',
                        date,
                        sellerName,
                        orderId: logisticService.id,
                        status,
                        paymentType,
                        amount: price.toFixed(2),
                        products
                    })
                })
            })
            this.items = _.sortBy(this.items, (item) => {
                return -getTimestamp(item.date)
            })
            //console.log('items', this.items)
        }
    },
    async mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                     if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        })
                        .then(() => {

                            callback(null)
                        })
                        return
                    }
                    callback(null)
                },
                (callback) => {
                    this.resident = this.getCurrentResident()
                    this.$store.dispatch('dataOrders/getOrders', {
                        residentId: this.resident_id
                    })
                    .then((apiOrders) => {
                        this.orders = _.filter(apiOrders, (order) => {
                            const orderDate = moment(order.createdOnUtc)
                            const month = orderDate.month()
                            const year = orderDate.year()
                            return month == this.month && year == this.year
                        })
                        //console.log('orders', this.orders)
                        callback(null)
                    })
                }
            ],
            (err, results) => {
                if (err) {
                    return
                }
                Oauth.checkUserRight(this.$store.getters['dataResidents/getResidentById'](this.resident_id), this.$router)
                this.updateItems()
                this.loaded = true
            }
        )
    }
}
</script>

<style lang="scss">
#resident-order-summary {
    color: #2C2C2C;

    .back-section {
        display: inline-flex;
        align-items: center;
        margin: 0px 0px 14px 0px;
        font-family: Montserrat;
        cursor: pointer;

        .back-btn {

            .vs-icon {
                font-size: 30px;
            }
        }
        .back-legend {
            margin: 0px 0px 0px 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 29px;
            color: rgba(var(--vs-primary), 1);
            user-select: none;
        }
    }

    .order {
        margin: 0px 0px 30px 0px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.170509);
        border-radius: 8px;

        .order-header {
            display: flex;
            align-items: center;
            padding: 21px 22px 21px 22px;

            .order-vendor {
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
            }
            .order-number {
                width: 150px;
                margin: 0px 0px 0px 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
            .order-date {
                width: 150px;
                margin: 0px 0px 0px 50px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
            }
            .order-status {
                width: 100px;
                display: flex;
                align-items: center;
                margin: 0px 0px 0px 150px;

                .status-icon {

                }
                .status-label {
                    margin: 0px 0px 0px 10px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFA253;
                }
            }
            .order-subscriptions {
                width: 100px;
                display: flex;
                align-items: center;
                margin: 0px 0px 0px 150px;

                .subscriptions-icon {

                }
                .subscriptions-label {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .order-price {
                width: 200px;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                text-align: right;
            }
        }

        .order-columns {
            display: flex;
            padding: 0px 21px 0px 21px;

            .column-title {
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }
            .column-quantity {
                width: 150px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }
            .column-price {
                width: 74px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }
        }

        .order-items {

            .order-item {
                display: flex;
                align-items: center;
                padding: 22px 22px 22px 22px;
                border-bottom: 1px solid #D8D8D8;

                .item-photo {
                    width: 62px;
                    height: 62px;
                    background: #A5A5A5;
                }
                .item-informations {
                    flex-grow: 1;
                    margin: 0px 0px 0px 18px;

                    .item-title {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                    }
                    .item-description {
                        margin: 4px 0px 0px 0px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 14px;
                    }
                    .item-legend {
                        margin: 4px 0px 0px 0px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
                .item-quantity {
                    height: 28px;
                    padding: 5px 28px 5px 28px;
                    border-radius: 3px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 17px;
                    text-align: center;
                    background: #A6A6A6;
                    color: #FFF;
                }
                .item-price {
                    width: 74px;
                    margin: 0px 0px 0px 86px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 17px;
                    text-align: left;
                }
            }
        }
    }
}
</style>
