import ServiceStatus from '@/constants/service-status'
import ProductStatus from '@/constants/product-status'
import PaymentTypes from '@/constants/payment-types'

function getServiceStatusIcon (status) {
    switch (status) {
        // case ServiceStatus.WAITING_SCORING:
        // case ServiceStatus.SCORING_OK:
        // case ServiceStatus.SCORING_KO:
        //     return
        // case ServiceStatus.ORDER_ACCEPTED:
        // case ServiceStatus.ORDER_CONSUMED:
        //     return require('@/assets/images/ehpad/ico-2.png')
        // case ServiceStatus.WAITING_ACCEPTANCE:
        // case ServiceStatus.ORDER_PENDING:
        //     return require('@/assets/images/ehpad/ico-1.png')
        case ServiceStatus.ORDER_REFUSED:
        case ServiceStatus.ORDER_CANCELLED:
        case ServiceStatus.ORDER_EXPIRED:
            return require('@/assets/images/ehpad/ico-3.png')
    }
}
function getProductStatusIcon (status) {
    switch (status) {
        // case ProductStatus.STAGING:
        //     returnOrderStatusViewer
        // case ProductStatus.RECEIVED:
        //     return require('@/assets/images/ehpad/ico-2.png')
        // case ProductStatus.SHIPPING:
        // case ProductStatus.SHIPPED:
        // case ProductStatus.WAITING_ACCEPTANCE:
        //     return require('@/assets/images/ehpad/ico-1.png')
        case ProductStatus.INCIDENT_OPEN:
        case ProductStatus.WAITING_REFUND:
        case ProductStatus.WAITING_REFUND_PAYMENT:
        case ProductStatus.REFUNDED:
        case ProductStatus.REFUSED:
        case ProductStatus.CANCELED:
            return require('@/assets/images/ehpad/ico-3.png')
    }
}

function getOrderStatusIcon (order) {
    switch (order.type) {
        case 'service':
            return getServiceStatusIcon(order.status)
        case 'product':
            return getProductStatusIcon(order.status)
    }
}
function getPaymentTypeIcon (type) {
    switch (type) {
        case PaymentTypes.Reccurring:
            return require('@/assets/images/ehpad/icon-subscriptions.svg')
        case PaymentTypes.OneShot:
            return require('@/assets/images/ehpad/icon-shopping-cart.svg')
    }
}
export {
    getServiceStatusIcon,
    getProductStatusIcon,
    getOrderStatusIcon,
    getPaymentTypeIcon
}
